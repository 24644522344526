import React , {Component} from 'react';
import './App.scss';
import Navbar from './components/Navbar/Navbar';
import Theme from './components/Theme/Theme';  
import Footer from './components/Footer/Footer'; 
import Recipies from './components/Recipies/Recipies'; 
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  render() {
    return(
    <div>
      <Navbar />
      <Recipies />
      <Theme />
      <Footer />
    </div>
  );
 }
}

export default App;
