import React from 'react';
import ScrollToTop from "react-scroll-to-top";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Fade from 'react-reveal/Fade';
import cakejars from './assests/cakejars.png';
import idli from './assests/idli.png';
import trufflecake from './assests/trufflecake.png';
import rajmachawal from './assests/rajmachawal.jpg';
import truffle from './assests/truffle.png';
import rasmalai from './assests/rasmalai.png';
import kulfi from './assests/kulfi.png';
import apple from './assests/apple.png';
import crispy from './assests/crispy.png';
import eggmeal from './assests/eggmeal.png';
import balubashi from './assests/balubashi.jpg';
import dalmakhni from './assests/dalmakhni.png';
import slideone from './assests/slideone.png';
import slidetwo from './assests/slidetwo.png';
import main from './assests/main.png';
import slidethree from './assests/slidethree.png';
import slidefour from './assests/slidefour.png';
import slidefive from './assests/slidefive.png';
import priti from './assests/priti.png';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import 'font-awesome/css/font-awesome.min.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Theme = ({onRouteChange}) => {

    return (
    <div className="hero">      
      <Fade bottom>
    <div className="mobileview">
      <main role="main">
      <div class="jumbotron">
        <div class="jumbotron-background"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h1 class="jumbotron-title">We bake ridiculously yummy cupcakes <br/> and cakes that are customized<br/> according to your <br/>choice!<br /></h1>
              <div class="row">
                <div class="col-md-8">
                </div>
              </div>
            </div>
            
            <div class="col-md-4">
              <div
                class="h-100 d-flex justify-content-center align-items-center">
                <div class="hero-image-container">
                <img src={rasmalai} alt="fusioncake"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    </div>
    <img src={main} alt="food" className="heroimage"/>
    </Fade>   

    <container fluid>
        <div class="row" id="aboutus">
        <div class="col-sm-5">
            <Fade left>
                <div>
                
                  <img src={priti} alt="deliciousdelightsbypritiowner"/>
                
                </div>
            </Fade>
        </div>
            <div class="col-sm-7">
            <Fade right>
                <div className ="white">
                <h1 className="sectionheading">About Me</h1>
                <p id="aboutcommontext">I, Priti Gulati, am pleased to introduce myself as a budding entrepreneur, who has successfully emerged as a home-based baker in the past few years. I didn't gush into this career by chance, it was my passion for cooking, since childhood which maneuvered me towards this profession. The culinary skills and an eye for purity, which I inherited from my mother, and the unconditional support provided by my husband and children, have been the pillars behind my success. My keen interest in cooking and baking and the appreciation shown by my friends and folks encouraged me to pursue this skill as my career. I am now a part of this community for almost 5 years. The journey began in 2014 when I started a home-based business, after that, there was no looking back. Apart from taking orders, I also conduct hands-on workshops where I pass on these skills to interested candidates. In my opinion, passion and purity are the basic ingredients for any cooking, thus to ensure quality at each step, I procure raw material, bake, decorate and give a finishing touch, all by myself.</p>
                </div>
            </Fade>
            </div>
            
        </div> 
    </container>

    <ScrollToTop smooth color="black" />

    <container fluid>
    <div class="back-img"></div>
    </container>

    <container fluid>
        <div class="row" id="recipes">
            <div class="col-sm-12">
           

            <Fade top>
              <h1 className="sectionheading">Explore Recipes</h1>
              </Fade>
              <Fade bottom>
              <input id="check" type="checkbox"/>
        
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Strawberry Smoothie
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
      <container fluid>
        <div class="row">
        <div class="col-sm-5">
            <Fade bottom>
            <img src={slideone} alt="strawberrysmoothie" className="recipieimage"/>
            </Fade>
        </div>
            <div class="col-sm-7">
      <Fade top>
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Ingredients
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. Strawberry Greek yogurt<br/>
                    2. Strawberries<br/>
                    3. Milk<br/>
                    4. banana (1/2)<br/>
                    5. Honey (optional)
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Instructions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. Use milk, then strawberry Greek yogurt.<br/>
                    2. Add the fruit: a bit of frozen banana (makes it thick and creamy!), frozen or fresh strawberries (obviously!), and some ice cubes (I like my smoothies thick and ice cold!).<br/>
                    3. Blend it up and serve! If your strawberries aren’t very sweet, you may want to swap the milk for juice or a bit of honey.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>
           </Fade>
            </div>
        </div> 
    </container>


        </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                         White Sauce Pasta
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <container fluid>
        <div class="row">
        <div class="col-sm-5">
            <Fade bottom>
            <img src={slidetwo} alt="whitesaucepaste" className="recipieimage"/>
            </Fade>
        </div>
            <div class="col-sm-7">
      <Fade top>
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Ingredients
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. Pasta<br/>
                    2. Butter, garlic and flour milk <br/>
                    3. Salt and pepper<br/>
                    4. Parsley and parmesan cheese
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Instructions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. Boil pasta for 15 mins with salt in it.<br/>
                    2. While pasta is boiling, melt butter in a medium sauce pan, add garlic and cook for 1 minute over medium heat. Add flour and cook for an addition minute, stirring constantly. Add milk and broth, stirring constantly.<br/>
                    3. Cook until sauce boils and thickens. Add parsley and parmesan cheese, salt and pepper. Stir until cheese has melted.<br/>
                    4. Mix in pasta and serve immediately.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        </div>
           </Fade>
            </div>
            
        </div> 
    </container>

    </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                         Crispy Tapioca Pearl Mixture
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <container fluid>
        <div class="row">
        <div class="col-sm-5">
            <Fade bottom>
            <img src={crispy} alt="whitesaucepaste" className="recipieimage"/>
            </Fade>
        </div>
            <div class="col-sm-7">
      <Fade top>
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Ingredients
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext"> 
                        1. Nylon Tapioca/ Sabudana (1/2 Cup)<br/>
                        2. Peanuts (1/2 Cup)<br/>
                        3. Almonds (1/2 Cup)<br/>
                        4. Raisins (1/4 CUP)<br/>
                        5. Cashew Nuts (1/2 Cup)<br/>
                        6. Makhana / Fox Nuts (1 Cup)<br/>
                        7. Black Salt (1 Tsp)<br/>
                        8. Palm Sugar (2 Tsp)<br/>
                        9. Black Pepper (1 Tsp)<br/>
                        10. Curry Patta Leaves (5/6 leaves)<br/>
                        11. Refined Oil for frying <br/>
                        12. Dried Coconut (2)<br/>
                        13. FAB Premium Oil Soluble Curry Patta Flavour (few drops)
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Instructions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext"> 
                      1. In a bowl place nylon sabudana.<br/>
                      2. Sprinkle some water over it and keep aside for 10 min Heat refined oil in kadai.<br/>
                      3. Add sabudana to oil and let them puffed up.<br/>
                      4. Remove them on paper lined plate to absorb extra oil.<br/>
                      5. In same kadai fry peanuts, almonds, chahew , makhane.<br/>
                      6. Then fry raisins till they puff up.<br/>
                      7. Remove them ,fry curry patta in same oil .Transfer everything to a bowl and mix them.<br/>
                      8. Now in small bowl take powdered sugar,salt,black pepper powder .Add FAB Premium Oil Soluble Curry Patta Flavour.<br/>
                      9. Mix well and add this to sabudana mixture .Our crispy sabudana Namkeen is ready.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        </div>
           </Fade>
            </div>
            
        </div> 
    </container>


    </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                         Green Apple Caramel Trifle
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <container fluid>
        <div class="row">
        <div class="col-sm-5">
            <Fade bottom>
            <img src={apple} alt="whitesaucepaste" className="recipieimage"/>
            </Fade>
        </div>
            <div class="col-sm-7">
      <Fade top>
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Ingredients
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    <p className="bold">1: CINNAMON APPLE </p>
                        Green Apple (100gm)<br/> 
                        Sugar (75gm)<br/>
                        Water (50ml)<br/>
                        a pinch Cinnamon powder<br/>
                        Fab Premium Oil Soluble Cinnamon Ceylon Flavour (few drops)<br/><br/>

                        <p className="bold">2: CARAMEL SAUCE </p>

                        Granulated Sugar (½ cup)<br/>
                        Butter2 tbs (room temp.)<br/>
                        Heavy Cream (¼ cup)<br/>
                        Fab Premium Oil Soluble Intense Irish Cream Flavour (few drops)<br/><br/>

                        <p className="bold">3: WHIPPED CREAM</p>

                        Fab Premium Oil Soluble Green Apple Flavour (few drops)<br/>
                        Cream (Whipped)<br/><br/>

                        <p className="bold">4: CRUNCHY GRANOLA</p>

                        Oats (50gms)<br/>
                        Sunflower Seeds (50gms)<br/>
                        Pumpkin Seeds (50gms)<br/>
                        Cranberry (50gms)<br/>
                        Fab Premium Oil Soluble Cinnamon Ceylon Flavour (few drops)<br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Instructions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. For cinnamon Apple: Wash, peel and chopped apples.
                          2.  Add sugar, water and cinnamon powder to it and place pan on heat.
                            Cook apples for 15-20 min.
                            until tender.
                          3.  Allow it to cool in room temperature.
                          4.  For caramel sauce: On medium low heat sugar until sugar melts and start to golden amber.
                          5.  Remove from heat, add butter and whisk until well combined.
                            Add heavy cream and whisk until constantly.
                            Cool caramel to room temperature.
                          6.  Add few drops of Fab Premium Oil Soluble Intense Irish Cream Flavour For whipped cream: Whip cream till stiff peaks, add a few drops Fab Premium Oil Soluble Green Apple Flavour For Crunchy Granola: You can use ready made granola or you can make it.
                          7.  Roast oats, sunflower seeds, pumpkin seeds in the oven 10-15 min.
                          8.  Add few drops of Fab Premium Oil Soluble Cinnamon Ceylon Flavour.
                            Add chopped cranberry to it.
                        <br/><br/>
                       <p className="bold">Assembling:<br/></p>
                    1. Take a shot glass.
                       2. Place some crunchy granola, add a layer of whipped cream over it.
                       3. Then add a layer of cinnamon apple.
                       4. Fourth layer of whipped cream and on top drizzle some caramel sauce and garnish it with some crunchy granola and serve chilled.
<br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        </div>
           </Fade>
            </div>
            
        </div> 
    </container>


                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                         Butterscotch Dessert
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <container fluid>
        <div class="row">
        <div class="col-sm-5">
            <Fade bottom>
            <img src={slidethree} alt="butterscotchdessert" className="recipieimage"/>
            </Fade>
        </div>
            <div class="col-sm-7">
      <Fade top>
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Ingredients
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    <p className="bold">For the sauce:</p>
                      1. 8 tablespoons unsalted butter (1 stick)<br/>
                      2. 2 tablespoons light corn syrup<br/>
                      3. 3⁄4 cup granulated sugar<br/>
                      4. 1⁄4 cup light brown sugar<br/>
                      5. 1⁄3 cup heavy cream<br/>
                      6. 1 teaspoon dark rum<br/>
                      7. 1 teaspoon pure vanilla extract<br/>
                      8. 1⁄2 teaspoon fine salt<br/>
                      9. 1⁄2 teaspoon fresh lemon juice<br/><br/>
                      <p className="bold">For the sundae:</p>
                      1. 2 scoops vanilla ice cream (or 1 scoop vanilla, 1 scoop chocolate)<br/>
                      2. 1/2 a ripe banana, sliced<br/>
                      3. 2 tablespoons lightly toasted pistachios<br/>
                      4. 2 tablespoons butterscotch sauce
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Instructions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. Heat the butter, corn syrup, and 1⁄4 cup of water in a medium saucepan over medium-low heat, stirring constantly, until the butter has melted.<br/>
                    2. Stir in the granulated sugar and the brown sugar and scrape down sides of pan with a rubber spatula. Bring the mixture to a boil over medium heat (don’t stir at this point). Cook until the mixture is light brown and a candy thermometer inserted in sauce reads 245°F, 6 to 8 minutes.<br/>
                    3. Remove from the heat. Carefully pour in the heavy cream, rum, vanilla extract, salt, and lemon juice. Stir to mix well. Cool to room temperature before using.<br/><br/>
                    <p className="bold">To assemble the sundae:</p>
                    1. Place the 2 scoops of ice cream in a bowl.<br/>
                    2. Scatter over the banana slices and pistachios, drizzle with the butterscotch sauce, and serve.<br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        </div>
           </Fade>
            </div>
            
        </div> 
    </container>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Creamy Tomato Soup
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <container fluid>
        <div class="row">
        <div class="col-sm-5">
            <Fade bottom>
            <img src={slidefour} alt="creamytomatosoup" className="recipieimage"/>
            </Fade>
        </div>
            <div class="col-sm-7">
      <Fade top>
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Ingredients
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                        For the Base <br/>
                        1. 1-1/2 tbsp oil <br/>
                        2. 1-1/2 tbsp unsalted butter <br/>
                        3. 1 bay leaf <br/>
                        4. 1 medium Onion, chopped into small chunks <br/>
                        5. 1/3 cup Celery, chopped<br/>
                        6. 4-5 cloves of Garlic<br/>
                        7. 2.2 lbs (1 kilo/abt 10 medium sized) red ripe Tomatoes, chopped - see Tips<br/>
                        8. 3-4 tbsp Cream or as needed<br/>
                        9. Salt and Pepper to taste<br/><br/>

                        <p className="bold"> Optional Additions for Sweetness/Texture/Color Can add any one, in combination or all</p>
                        1 small-medium Carrot, chopped<br/>
                        1 small Red Bell Pepper<br/>
                        1/4 cup Sweet Potato, peeled and chopped into small chunks<br/><br/>

                        <p className="bold">Traditional Restaurant thickeners/additions (optional - Not needed if vegetables are added)</p>
                        1 tbsp Rice flour/Corn flour<br/>
                        1-2 tsp Sugar to balance sourness<br/>
                        1 tsp Kashmiri Chilli powder - for heat and color<br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Instructions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. In your 2/3 Litre standardized pressure cooker ( I use 3L), add 1.5 tbsp oil and 1.5 tbsp butter along with bay leaf.  I don't add water, but if you fear burning, add 2-3 tbsp water as well. <br/>
                    2. Next add onion, celery, garlic and bell pepper. I add the bell pepper for texture as well as color. The celery is peppery and compliments the sweet tomatoes. You can add green chillies if you like at this point.<br/>
                    3. Next add in the tomatoes. Add salt and pepper to taste.<br/>
                    4. Close with the lid, place the weight and cook on high heat. If cooking over stove top, make sure the flames don't come up the sides.<br/>
                    5. We are looking for 3 long whistles and for this quantity/cooker combo takes around 10-11 mins with the pressure building/pressure valve popping up at about 8 minutes.The number of whistles, I find, mostly depends on how watery your tomatoes are. There are more whistles with more water content in tomatoes. So, in that case, aim for around 10 minutes cooking time (for this qty and cooker). <br/>
                    6. Once done, switch off heat and manually release pressure. Use a spatula to avoid scalding.<br/>
                    7. This is how it looks upon opening.  You can use a hand blender at this point.<br/>
                    8. I transferred it to my food processor since I love how well it purees my soup. <br/>
                    9. Add water or milk and/or cream to adjust the consistency as per your preference. I added just a little more water since we like it with a thick'ish(!) consistency.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        </div>
           </Fade>
            </div>
            
        </div> 
    </container>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Mango Lassi Drink
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <container fluid>
        <div class="row">
        <div class="col-sm-5">
            <Fade bottom>
            <img src={slidefive} alt="Mangolassi" className="recipieimage"/>
            </Fade>
        </div>
            <div class="col-sm-7">
      <Fade top>
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Ingredients
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                        Milk (500 ml)<br/>
                        Honey-flavoured yoghurt (250gm)<br/>
                        Frozen mango, chopped (500gm)<br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Instructions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. Combine all ingredients in a blender. Blend well to combine.<br/> 
                    2. Serve cold. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        </div>
           </Fade>
            </div>
           
        </div> 
    </container>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                         Kulfi Phirni Falooda Jars Fusion Dessert
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <container fluid>
        <div class="row">
        <div class="col-sm-5">
            <Fade bottom>
            <img src={kulfi} alt="khulfiphirni" className="recipieimage"/>
            </Fade>
        </div>
            <div class="col-sm-7">
      <Fade top>
        <div class="test"> 
         <Accordion  allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Ingredients
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">                    
                        1. Basmati Rice (1/4 Cup) <br/>
                        2. Full Cream Milk (1 Ltr) <br/>
                        3. Green Cardamom Pods (2-3) <br/>
                        4. Sugar (3/4 Cup) <br/>
                        5. Almond (3 tbs) <br/>
                        6. Pistachio (2 tbsp) <br/>
                        7. Saffron Strands (4-5) <br/>
                        8. Readymade Falooda Sev (1 Pkt) <br/>
                        9. Sabja Seeds/Basil Seeds (2 tbsp) <br/>
                        10. Fab Premium Oil Soluble Kulfi Flavour (few drop) <br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Instructions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className="recipetext">
                    1. Blanch and sliced almonds and pistachios.
                      Wash and soak rice for 30 min.
                        Discard water and grind rice in grinder jar.
                        You can add a little water to make coarse paste.<br/>
                    2. In a heavy bottom pan heat milk and let it come to a boil.
                        Boil milk at least for 10 min.
                        on medium low heat.
                        Stir in between.
                        Add ground cardamom, saffron and sugar to milk.
                        Boil for another 10-12 min.<br/>
                    3. Now it is time to add ground rice to milk.
                        Stir constantly.
                        Cook till rice grains are soft and milk thickened.<br/>
                    4. At last add few drops of Fab Premium Oil Soluble Kulfi Flavour.
                        Let it cool at room temperature.
                        Then chill it in the refrigerator for a few hours.<br/><br/>
                       <p className="bold">Assembling:<br/></p>
                    5. In a tall glass add 2 tbs of phirni, then add a layer of soaked sabja seeds.
                        Again pour some phirni, sprinkle some chopped almonds and pistachios.<br/>
                    6. I had some barfi so I added that too.
                        On top add falooda sev.
                        Drizzle some roohafza and top it with sliced almonds and pistachios.<br/>
                    7. Chill it and serve.
                        Enjoy this fusion dessert during festive season or on special occasions.
<br/>
                   
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        </div>
           </Fade>
            </div>
           
        </div> 
    </container>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        </div>
           </Fade>
            </div>
        </div> 
        
    </container>

      

   <container fluid>
    <div id="gallery">
    <Fade top>
    <h1 className="sectionheading">Gallery</h1>
    </Fade>
    <div class="row1"> 
  <div class="column1">
  <div class="wrapper">
    <div class="image">
        <img src={cakejars} alt="cakejars"/>
        <div class="text">Motichoor Ladoo Rabri Cake Jar <br/>
        Cake Jars are up and ready to be ordered. When are you comin' to get them! <br/>
        #madewithlove #westdelhibakers #westdelhibakery #cakesofinstagram  #indiansweets #cakejars #fusioncakejars #fusioncake #dessertsinjars </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="image">
        <img src={idli} alt="idli"/>
        <div class="text">Who else is craving for some spicy food?  <br/>
        #idlimanchurian #madewithlove #quarintinecooking #healthyfood #madewithlove</div>
    </div>
  </div>
  </div>
  
  <div class="column1">
  <div class="wrapper">
    <div class="image">
        <img src={trufflecake} alt="trufflecake"/>
        <div class="text">Lately, I have been flooded with delicious chocolate truffle Cake orders and it feels so unreal that we are able to deliver cakes with the favorite design of what customers ask for! <br/>
        #madewithlove #westdelhibakers #westdelhibakery #chocolatetrufflecake #chocolatecake #trufflecake</div>
    </div>
  </div>
  <div class="wrapper">
    <div class="image">
        <img src={rajmachawal} alt="rajmachawal"/>
        <div class="text">I know I can't make everyone happy,
        I am not rajma chawal XD<br/>
        #rajmachawal #foodpost #foodphotography #foodinstagram #rajmachawalforlife #rajmachawallove #instapic #instagram #goodfood #deliciousdelightsbypriti #delightful #delhibaker #westdelhi #janakpuri #sodelhi #sodelicious #idbdelhi </div>
    </div>
  </div>
  </div>
  <div class="column1">
  <div class="wrapper">
    <div class="image">
        <img src={truffle} alt="truffle"/>
        <div class="text">As the summers are kickin' in; I ask myself what's better than having delicious mango tropical truffle.<br/>
        #sugarrush #mangotriffle #summerdelights #delightful #delhibaker #janakpuri #sodelhi #sodelicious #idbdelhi</div>
    </div>
  </div>
  <div class="wrapper">
    <div class="image">
        <img src={dalmakhni} alt="rasmalai"/>
        <div class="text">So, today I thought of going for a platter option by having some delicious soya chaap pineapple raita, Dal makhniand pyaaz naan. Swipe left onto calm your eyes!<br/>
        #yummylicious #quarintinecooking #foodpost #foodplatter #delicious</div>
    </div>
  </div>
  </div>
  <div class="column1">
  <div class="wrapper">
    <div class="image">
        <img src={eggmeal} alt="eggmeal"/>
        <div class="text">Mid-day meals are never skipped by legends<br/>
        #eggricemeal #Idb #sodelhi #idbdelhi #madewithlove #preorder #middaymeal #meals</div>
    </div>
  </div>
  <div class="wrapper">
    <div class="image">
        <img src={balubashi} alt="balubashi"/>
        <div class="text">We all are close to rakshabandhan celebrations and now is the time when we plan to gift sweets to our loved ones. These yummy Balushai sweets are just one call away, so grab your phones and make an order.<br/>
        #traditional #indiansweets #rakshabhandhan #balushai #Idb #sodelhi #idbdelhi #madewithlove</div>
    </div>
  </div>
  </div>
    </div> 
    </div> 
    </container>

    <container fluid>
        <div class="row" id="classes"> 
            <div class="col-sm-5">
            <Fade left>
                <div >
                <h1 className="sectionheading">Classes</h1>
                <p id="commontext">Most of the classes are tailored according to your suitability</p>
                <div className="warning"><p id="commontext">Due to covid-19 classes have been cancelled. Classes will be updated until further notice.</p></div>
                {/* botton */}
                {/* <a href="!#" class="cta">
                <span>Click me</span>
                <svg width="13px" height="10px" viewBox="0 0 13 10">
                    <path d="M1,5 L11,5"></path>
                    <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
                </a> */}
                </div>
            </Fade>
        </div>
        <div class="col-sm-7" id="reviews">
            <Fade right>
                <div className="reviews">
                <h1 className="sectionheading">Reviews</h1>
                {/* <Testimonials /> */}
                <Carousel
                    showArrows={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    interval={6100}
                >
                    <div>
                    <div className="myCarousel">
                        <h3>Shrishti Malyan</h3>
                        <p>
                        Yummy and fab taste, ambience, service everything was on point <span role="img" aria-label="love">🍰🍰💖💗</span>
                        Thank you much for this amazing treat to us <span role="img" aria-label="love">👌</span>
                        </p>
                    </div>
                    </div>

                    <div>
                    <div className="myCarousel">
                        <h3>Simran</h3>
                        <p>
                        The best bakery I've ever come across <span role="img" aria-label="love">😍</span> Mouth watering delicacies <span role="img" aria-label="love">❤💯</span> Taste it once and you'll crave for more and more...<span role="img" aria-label="love">😝</span> I bet you haven't tasted anything like it <span role="img" aria-label="love">💯</span>
                        </p>
                    </div>
                    </div>

                    <div>
                    <div className="myCarousel">
                        <h3>Shagun Sharma</h3>
                        <p>
                        Perfect serving, mouthwatering stuffs and cakes. I have been there had the best and super tasty cupcakes by delicious delights. They are offering the marvellous stuffs with different varieties.
                        </p>
                    </div>
                    </div>

                    <div>
                    <div className="myCarousel">
                        <h3>Mamtaj</h3>
                        <p>
                        Bakery items are just amazing and mouth watering.<span role="img" aria-label="love">😋</span> I love the cakes made here. Keep up the impeccable work, would love to see the best of you.<span role="img" aria-label="love">😊</span>
                        </p>
                    </div>
                    </div>

                    <div>
                    <div className="myCarousel">
                        <h3>Sneha Mathew</h3>
                        <p>
                        I have already tried and loved it,and i would totally recommend others to try it as well, you won't be disappointed with the order or the service for sure. Superb taste which leaves you fully satisfied.
                        </p>
                    </div>
                    </div>

                    <div>
                    <div className="myCarousel">
                        <h3>Juhu Sawlani Lalwani</h3>
                        <p>
                        Excellent cooking. Priti ma'am, thank you so much for teaching me such a delicious chaap recipes. I enjoyed Cooking with you and chaaps are really delicious. And thanks for your wonderful tips.
                        </p>
                    </div>
                    </div>

                    <div>
                    <div className="myCarousel">
                        <h3>Jyoti Batra</h3>
                        <p>
                        Priti ji is a wonderful chef. Learning from her is a great experience. I learnt so many cakes from her and she always gives wonderful tips. I like her teaching style so much. Thanks Priti ji.
                        </p>
                    </div>
                    </div>
      </Carousel>
                </div>
            </Fade>
            </div>
        </div> 
    </container>

    </div>
    );    
}

export default Theme;
