import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import Fade from 'react-reveal/Fade';

const Footer = () => {
    return (
        <div>
           
            <footer className="page-footer">
            
            <div className="container">
                
                <div className="row text-center d-flex justify-content-center pt-5 mb-3">
                
                <div className="col-md-2 mb-3">
                    <h6 className="font-weight-bold">
                    <a href="#aboutus">About us</a>
                    </h6>
                </div>
                
                <div className="col-md-2 mb-3">
                    <h6 className="font-weight-bold">
                    <a href="#recipes">Recipes</a>
                    </h6>
                </div>
                
                <div className="col-md-2 mb-3">
                    <h6 className="font-weight-bold">
                    <a href="#gallery">Gallery</a>
                    </h6>
                </div>
                
                <div className="col-md-2 mb-3">
                    <h6 className="font-weight-bold">
                    <a href="#classes">Classes</a>
                    </h6>
                </div>
                
                <div className="col-md-2 mb-3">
                    <h6 className="font-weight-bold">
                    <a href="#reviews">Reviews</a>
                    </h6>
                </div>
                
                </div>
                
                <hr className="rgba-black-light"  />

                <div className="row d-flex text-center justify-content-center mb-md-0 mb-4">
                
                <div className="col-md-8 col-12 mt-5">
                    <Fade bottom>
                    <p id= "footertext">@deliciousdelightsbypriti <br/>
                    +91 9599930205
                    </p>
                    </Fade>
                </div>
                
                </div>
            
                <div className="row pb-3 text-center d-flex justify-content-center">
                
                <div className="col-md-12">

                    <div className="mb-5 flex-center">

                    <a href="https://www.facebook.com/deliciouscookeryclasses/" className="fb-ic" alt="facebook"  rel="noopener noreferrer" target="_blank">
                        <i className="fa fa-facebook" > </i>
                    </a>
                    
                    <a href="mailto:gulatipriti1@gmail.com" className="tw-ic" alt="envelope" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-envelope" > </i>
                    </a>
                    
                    <a href="https://www.instagram.com/deliciousdelightsbypriti/?hl=en"  rel="noopener noreferrer" className="ins-ic" alt="instagram" target="_blank" >
                        <i className="fa fa-instagram"> </i>
                    </a>

                    <a href="https://www.zomato.com/ncr/delicious-delights-by-priti-janakpuri-new-delhi" className="ins-ic" alt="zomato" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-cutlery "> </i>
                    </a>
                    </div>
                </div>  
                </div>
            </div>
        </footer>
        </div>
    );    
}

export default Footer;