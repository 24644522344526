import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import Fade from 'react-reveal/Fade';

const Navbar = () => {
    return (
  <div>
     <header>
        <input id="nav" type="checkbox"/>
        <label for="nav"></label>      
        <nav>
          <ul>
            <li><a href="#aboutus" className="shover">About us</a></li>
            <li><a href="#recipes">Recipes</a></li>
            <li><a href="#gallery">Gallery</a></li>
            <li><a href="#classes">Classes</a></li>
            <li><a href="#reviews">Reviews</a></li>
          </ul>
        </nav>
      
      </header>
      <div id="fixed-social">
        <div>
          <a href="https://www.facebook.com/deliciouscookeryclasses/" className="fixed-facebook" target="_blank" rel="noopener noreferrer"><i class="fa fa-facebook"></i></a>
        </div>
        <div>
          <a href="https://www.instagram.com/deliciousdelightsbypriti/?hl=en" rel="noopener noreferrer" className="fixed-instagram" target="_blank"><i class="fa fa-instagram" ></i></a>
        </div>
        <div>
          <a href="mailto:gulatipriti1@gmail.com" className="fixed-mail" target="_blank" rel="noopener noreferrer"><i class="fa fa-envelope"></i></a>
        </div>
        <div>
          <a href="https://www.zomato.com/ncr/delicious-delights-by-priti-janakpuri-new-delhi" className="fixed-mail" target="_blank" rel="noopener noreferrer"><i class="fa fa-cutlery"></i></a>
        </div>
        </div>
      
      <hr/>
      <Fade top>
      <h1 className="Logo">Delicious Delights By Priti</h1>
      </Fade>
  </div>
    );    
}

export default Navbar;
